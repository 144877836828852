export const colourScheme = {
    fullWhite: "#FFFFFF",
    white: "#E8EAF0",

    primaryDark: "#121425",
    primary: "#1A1C34",
    primaryLight: "#2C2E52",

    secondaryLight: "#7AA8E3", // Lightened for accent use
    secondary: "#4F80CC", // Slightly darker and richer blue
    secondaryDark: "#456CAB", // Darker secondary for accent areas

    successLight: "#61E0B1", // Adjusted to make more vibrant
    success: "#37C296", // Darkened slightly for contrast
    successDark: "#2CA478", // Darkened for hover or active states

    alertLight: "#F2D09A", // Keep this for light warnings
    alert: "#F0B853", // Adjusted for better contrast
    alertDark: "#E6A040", // Darkened for hover or active states

    errorLight: "#F48A87", // Keep this for lighter error states
    error: "#EB4D4B", // Slightly darker error color
    errorDark: "#D84341", // Darkened for hover or active states

    greyLight: "#B0B0B3", // Slightly darkened for consistency
    grey: "#7D7F83", // Darker grey for text or inactive elements
    greyDark: "#3B3D49", // Darkened for contrast and depth

    darkGradientStart: "#1B1C2D", // Slightly darker start for background gradient
    darkGradientEnd: "#101011", // Darker end for more depth in gradient

    secondaryGradientStart: "#2C3E50", // Keep the same for secondary gradient
    secondaryGradientEnd: "#4CA1AF", // Keep the same for secondary gradient
    shadow: "rgba(0, 0, 0, 0.5)", // Shadow color for elevation

    bgGradient1: "#0F172A", // A deep blue-gray
    bgGradient2: "#1E293B", // A lighter blue-gray
    bgGradient3: "#334155", // An even lighter blue-gray
    bgAccent1: "#3B82F6", // A vibrant blue for accents
    bgAccent2: "#10B981", // A vibrant green for accents

    gold: "#F0B853",
    goldLight: "#F2D09A",
};

export const hexToRgba = (hex: string, alpha: number): string => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

export const hexToRgb = (hex: string): string => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    return `rgb(${r}, ${g}, ${b})`;
};

export const darken = (color: string, amount: number) => {
    // Function to darken a hex color
    let usePound = false;

    if (color[0] === "#") {
        color = color.slice(1);
        usePound = true;
    }

    let num = parseInt(color, 16);

    let r = (num >> 16) - amount;
    if (r < 0) r = 0;
    if (r > 255) r = 255;

    let g = ((num >> 8) & 0x00ff) - amount;
    if (g < 0) g = 0;
    if (g > 255) g = 255;

    let b = (num & 0x0000ff) - amount;
    if (b < 0) b = 0;
    if (b > 255) b = 255;

    return (
        (usePound ? "#" : "") +
        ((r << 16) | (g << 8) | b).toString(16).padStart(6, "0")
    );
};
