import styled, { css, keyframes } from "styled-components";

import { device } from "utils/sizes";
import { colourScheme } from "utils/styling/colours";

export const PopoverItem = styled.div`
    display: flex;
    align-items: center;
    color: ${colourScheme.white};
    cursor: pointer;
    transition: transform 0.1s ease-in-out;
    flex-direction: row;

    & > span {
        white-space: nowrap;
    }

    &:hover {
        transform: scale(1.03);
    }
`;

const subtlePulse = keyframes`
  0% { box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.1); }
  50% { box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.2); }
  100% { box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.1); }
`;

const gradientShift = keyframes`
    0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
`;

export const AvatarWrapper = styled.div<{ subscriptionstyle: any }>`
    position: relative;
    width: 3.3rem;
    height: 3.3rem;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    overflow: hidden;

    ${(props) =>
        props.subscriptionstyle.gradientBorder &&
        css`
            background: ${props.subscriptionstyle.gradientBorder};
            background-origin: border-box;
            background-clip: content-box, border-box;
            ${props.subscriptionstyle.animation ===
                "subtlePulse 3s infinite, gradientShift 5s linear infinite" &&
            css`
                animation:
                    ${subtlePulse} 3s infinite,
                    ${gradientShift} 5s linear infinite;
            `}
            ${props.subscriptionstyle.animation === "subtlePulse" &&
            css`
                animation: ${subtlePulse} 3s infinite;
            `}
        `}

    ${(props) =>
        props.subscriptionstyle.borderColor &&
        css`
            border: 3px solid ${props.subscriptionstyle.borderColor};
            overflow: hidden;
            ${props.subscriptionstyle.animation === "subtlePulse" &&
            css`
                animation: ${subtlePulse} 3s infinite;
            `}
        `}
`;

export const ClickableDiv = styled.div`
    display: inline-block;
    cursor: pointer;
    width: 3.5rem;
    height: 3.5rem;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    &:focus {
        outline: none;
        box-shadow: 0 0 0 2px ${colourScheme.secondary};
    }

    &:disabled {
        cursor: not-allowed;
        opacity: 0.6;
    }
`;

export const AvatarImage = styled.img`
    width: 3rem;
    height: 3rem;
    border-radius: 100%;
    object-fit: cover;
`;

export const DefaultAvatar = styled.div`
    width: 100%;
    height: 100%;
    background-color: ${colourScheme.primaryLight};
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const NewBadge = styled.div`
    background: linear-gradient(
        90deg,
        ${colourScheme.successLight} 0%,
        ${colourScheme.successDark} 100%
    );
    color: ${colourScheme.primary};
    padding: 0.25rem 0.25rem;
    border-radius: 1rem;
    font-size: 0.5rem !important;
    font-weight: bold;
    vertical-align: top;

    ${device.md} {
        margin-left: 0.5rem;
        font-size: 0.6rem !important;
    }

    ${device.xl} {
        white-space: nowrap;
    }
`;
