/* eslint-disable indent */
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { captureException } from "@sentry/nextjs";
import { uniqueId } from "lodash";
import { usePathname } from "next/navigation";
import { useRouter } from "next/router";

import {
    AppReducerActionType,
    EditType,
    IError,
    IFreeGenModelDTO,
    ImageDTO,
    IModelConfig,
    IPollGenerateImage,
    IPresetDTO,
    IPromptOptions,
    PresetType,
    RequestType,
    UserPremiumType,
} from "types";

import {
    editImage,
    poll,
    pollEditImage,
    savePreset,
    updatePreset,
    voteOnImage,
} from "./actions";
import { AppContext } from "./contexts";

export const useDetectClickOut = (initState: boolean) => {
    const triggerRef = useRef<any>(null); // optional
    const nodeRef = useRef<any>(null); // required
    const pathname = usePathname();

    const [show, setShow] = useState(initState);
    const handleClickOutside = (event: any) => {
        if (triggerRef.current && triggerRef.current.contains(event.target)) {
            return setShow(!show);
        }

        if (nodeRef.current && !nodeRef.current.contains(event.target)) {
            return setShow(false);
        }
    };
    useEffect(() => {
        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    });

    useEffect(() => {
        const handleResize = () => {
            setShow(false);
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    });

    const handleChildButtonClick = () => {
        setShow(false);
    };

    useEffect(() => {
        if (nodeRef.current) {
            const childButton = nodeRef.current.querySelector("button");
            if (childButton) {
                childButton.addEventListener("click", handleChildButtonClick);
            }
        }
        return () => {
            if (nodeRef.current) {
                // eslint-disable-next-line react-hooks/exhaustive-deps
                const childButton = nodeRef.current.querySelector("button");
                if (childButton) {
                    childButton.removeEventListener(
                        "click",
                        handleChildButtonClick
                    );
                }
            }
        };
    }, []);

    useEffect(() => {
        setShow(false);
    }, [pathname]);

    return {
        triggerRef,
        nodeRef,
        show,
        setShow,
    };
};

export const useReferralCode = () => {
    const router = useRouter();
    const { state, dispatch } = useContext(AppContext);

    useEffect(() => {
        const referralCode = router.query.referralCode as string;
        if (referralCode) {
            dispatch({
                type: AppReducerActionType.SET_REFERRAL_CODE,
                payload: {
                    ...state,
                    referralCode: referralCode,
                },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [router.query, dispatch]);
};

export const useGclid = () => {
    const router = useRouter();
    const { state, dispatch } = useContext(AppContext);

    useEffect(() => {
        const gclid = router.query.gclid as string;
        if (gclid) {
            dispatch({
                type: AppReducerActionType.SET_GCLID,
                payload: {
                    ...state,
                    gclid: gclid,
                },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [router.query, dispatch]);
};

type GenerateImageFunction = (
    formData: FormData,
    isPremium: boolean
) => Promise<{ requestId?: string; error?: string | IError }>;

type PollGenerateImageFunction = (
    requestId: string,
    isPremium: boolean
) => Promise<IPollGenerateImage>;

type FormValues = {
    [key: string]: string;
};

interface UseGenerateImageOptions {
    requestType: RequestType | null;
    prompt: string | IPromptOptions | undefined;
    file?: File | null;
    charSheetUrl?: string | null;
    generateImageFunction: GenerateImageFunction;
    pollGenerateImageFunction: PollGenerateImageFunction;
    imageFile: File | null;
    selectedModel: IModelConfig | null;
    manualPrompt: boolean;
    formValues: FormValues;
    config: IFreeGenModelDTO | null;
    isPremium: boolean;
    useCustomConfig: boolean;
    useModel: boolean;
    goldCost: number;
    setError: (error: string) => void;
    setLoading: (loading: boolean) => void;
    handleSetImages: (image: string | ImageDTO[]) => void;
    includeArtStyle: boolean;
    controlNetImage?: string;
}

interface UseGenerateVideoOptions {
    requestType: RequestType | null;
    prompt: string | IPromptOptions | undefined;
    generateImageFunction: GenerateImageFunction;
    imageFile: File | null;
    selectedModel: IModelConfig | null;
    manualPrompt: boolean;
    formValues: FormValues;
    config: IFreeGenModelDTO | null;
    isPremium: boolean;
    useCustomConfig: boolean;
    useModel: boolean;
    goldCost: number;
    setError: (error: string) => void;
    setLoading: (loading: boolean) => void;
    setVideoLoading: (loading: boolean) => void;
}

export const useGenerateImage = (options: UseGenerateImageOptions) => {
    const {
        requestType,
        prompt,
        file,
        charSheetUrl,
        generateImageFunction,
        pollGenerateImageFunction,
        imageFile,
        selectedModel,
        manualPrompt,
        formValues,
        config,
        isPremium,
        useCustomConfig,
        useModel,
        goldCost,
        setError,
        setLoading,
        handleSetImages,
        includeArtStyle,
        controlNetImage,
    } = options;
    const { state, dispatch } = useContext(AppContext);

    const handleGenerateImage = useCallback(
        async (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            setError("");

            // Check if user is logged in
            if (!state.isLoggedIn) {
                e.stopPropagation();
                dispatch({
                    type: AppReducerActionType.SHOW_AUTH_MODAL,
                    payload: {
                        ...state,
                        showAuthModal: true,
                    },
                });
                return;
            }

            // Check if user has enough gold
            if (
                state.userPremiumLevel === UserPremiumType.NONE &&
                state.gold < goldCost
            ) {
                dispatch({
                    type: AppReducerActionType.SHOW_SUBSCRIPTION_TABLE,
                    payload: {
                        ...state,
                        showSubscriptionTable: true,
                    },
                });
                return;
            } else if (state.gold < goldCost) {
                dispatch({
                    type: AppReducerActionType.SHOW_PRICING_TABLE,
                    payload: {
                        ...state,
                        showPricingTable: true,
                    },
                });
                return;
            }

            if (
                (requestType == null || requestType == RequestType.CHARACTER) &&
                !manualPrompt &&
                !file &&
                !charSheetUrl
            ) {
                toast.error(
                    "Please provide a valid Character Sheet File or URL."
                );
                return;
            }

            if (manualPrompt && !prompt) {
                toast.error("Please provide a valid prompt.");
                return;
            }

            // Filter form values based on manual prompt
            const filteredFormValues: FormValues = manualPrompt
                ? Object.keys(formValues)
                      .filter(
                          (
                              key
                          ): key is
                              | "Number of Images"
                              | "Size"
                              | "Negative Prompt"
                              | "Process Shading"
                              | "ControlNet Weight"
                              | "Enhance Prompt"
                              | "Art Style" =>
                              key === "Number of Images" ||
                              key === "Size" ||
                              key === "Enhance Prompt" ||
                              key === "Process Shading" ||
                              key === "ControlNet Weight" ||
                              key === "Negative Prompt" ||
                              (key === "Art Style" && includeArtStyle)
                      )
                      .reduce((obj, key) => {
                          obj[key] = formValues[key];
                          return obj;
                      }, {} as FormValues)
                : formValues;

            // Create FormData and append necessary fields
            const formData = new FormData();

            if (file) {
                formData.append("file", file);
            }
            if (charSheetUrl) {
                formData.append("url", charSheetUrl);
            }

            if (requestType) {
                formData.append("requestType", requestType);
            }

            if (controlNetImage) {
                formData.append("controlNetImage", controlNetImage);
            }

            if (prompt) {
                if (typeof prompt === "string") {
                    formData.append("prompt", prompt);
                } else {
                    formData.append(
                        "prompt",
                        prompt.shortName || prompt.fullName
                    );
                }
            }

            if (imageFile && selectedModel?.imageToImage) {
                formData.append("referenceImage", imageFile);
            }

            if (config && !isPremium && (useCustomConfig || useModel)) {
                formData.append("model", JSON.stringify(config));
            }

            if (filteredFormValues) {
                for (const [key, value] of Object.entries(filteredFormValues)) {
                    formData.append(`requestFields[${key}]`, value);
                }
            }

            if (manualPrompt) {
                formData.append("manualPrompt", String(manualPrompt));
            }

            setLoading(true);

            // Call the generate image function
            const { requestId, error } = await generateImageFunction(
                formData,
                isPremium && !!state.gold
            );
            setError(typeof error === "string" ? error : error?.message || "");

            if (requestId) {
                // Poll for the generated image
                const { image, error } = await poll(
                    async () => {
                        const result = await pollGenerateImageFunction(
                            requestId,
                            isPremium && !!state.gold
                        );
                        if (result.error && typeof result.error === "object") {
                            console.log("Error details:", result.error);
                        }
                        return result;
                    },
                    ({ image, error }) => {
                        // Continue polling only if there is no image and no critical error
                        if (!!image) return false; // Stop polling when an image is retrieved
                        if (typeof error === "string") return false;
                        if (typeof error === "object") return false;
                        return true; // Continue polling otherwise
                    },
                    5000
                );
                if (error) {
                    if (typeof error === "object") {
                        toast.error(
                            error.message ||
                                "An unknown error occurred while generating the image and your gold has been refunded. Please try again."
                        );
                        dispatch({
                            type: AppReducerActionType.SET_GOLD,
                            payload: { ...state, gold: state.gold + goldCost },
                        });
                    } else if (typeof error === "string") {
                        toast.error("The request timed out. Please try again.");
                        dispatch({
                            type: AppReducerActionType.SET_GOLD,
                            payload: { ...state, gold: state.gold + goldCost },
                        });
                    }
                    setError(error.message || "An unknown error occurred");
                }
                if (image) {
                    handleSetImages(image);
                    dispatch({
                        type: AppReducerActionType.SET_GOLD,
                        payload: { ...state, gold: state.gold - goldCost },
                    });
                }
            }

            setLoading(false);
        },
        [
            setError,
            state,
            goldCost,
            manualPrompt,
            formValues,
            file,
            charSheetUrl,
            requestType,
            prompt,
            imageFile,
            selectedModel?.imageToImage,
            config,
            isPremium,
            useCustomConfig,
            useModel,
            setLoading,
            generateImageFunction,
            dispatch,
            handleSetImages,
            pollGenerateImageFunction,
            includeArtStyle,
            controlNetImage,
        ]
    );

    return handleGenerateImage;
};

export const useGenerateVideo = (options: UseGenerateVideoOptions) => {
    const {
        requestType,
        prompt,
        generateImageFunction,
        imageFile,
        selectedModel,
        formValues,
        config,
        isPremium,
        useCustomConfig,
        useModel,
        goldCost,
        setError,
        setLoading,
        setVideoLoading,
    } = options;
    const { state, dispatch } = useContext(AppContext);

    const handleGenerateVideo = useCallback(
        async (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            setError("");

            // Check if user is logged in
            if (!state.isLoggedIn) {
                e.stopPropagation();
                dispatch({
                    type: AppReducerActionType.SHOW_AUTH_MODAL,
                    payload: {
                        ...state,
                        showAuthModal: true,
                    },
                });
                return;
            }

            // Check if user has enough gold
            if (
                state.userPremiumLevel === UserPremiumType.NONE &&
                state.gold < goldCost
            ) {
                dispatch({
                    type: AppReducerActionType.SHOW_SUBSCRIPTION_TABLE,
                    payload: {
                        ...state,
                        showSubscriptionTable: true,
                    },
                });
                return;
            } else if (state.gold < goldCost) {
                dispatch({
                    type: AppReducerActionType.SHOW_PRICING_TABLE,
                    payload: {
                        ...state,
                        showPricingTable: true,
                    },
                });
                return;
            }

            if (!prompt) {
                toast.error("Please provide a valid prompt.");
                return;
            }

            // Create FormData and append necessary fields
            const formData = new FormData();

            if (requestType) {
                formData.append("requestType", requestType);
            }

            if (prompt) {
                if (typeof prompt === "string") {
                    formData.append("prompt", prompt);
                } else {
                    formData.append(
                        "prompt",
                        prompt.shortName || prompt.fullName
                    );
                }
            }

            if (imageFile && selectedModel?.imageToImage) {
                formData.append("referenceImage", imageFile);
            }

            if (config && !isPremium && (useCustomConfig || useModel)) {
                formData.append("model", JSON.stringify(config));
            }

            if (formValues) {
                for (const [key, value] of Object.entries(formValues)) {
                    formData.append(`requestFields[${key}]`, value);
                }
            }

            formData.append("manualPrompt", "true");

            setLoading(true);
            // Call the generate image function
            const { requestId, error } = await generateImageFunction(
                formData,
                isPremium && !!state.gold
            );
            setError(typeof error === "string" ? error : error?.message || "");

            if (requestId) {
                // Poll for the generated image
                setVideoLoading(true);
                dispatch({
                    type: AppReducerActionType.SET_GOLD,
                    payload: { ...state, gold: state.gold - goldCost },
                });
            }

            setLoading(false);
        },
        [
            setError,
            state,
            goldCost,
            prompt,
            formValues,
            requestType,
            imageFile,
            selectedModel?.imageToImage,
            config,
            isPremium,
            useCustomConfig,
            useModel,
            setLoading,
            generateImageFunction,
            dispatch,
            setVideoLoading,
        ]
    );

    return handleGenerateVideo;
};

export const useImageVoting = (
    images: ImageDTO[],
    selectedImage: ImageDTO | null,
    setImages: (images: ImageDTO[]) => void,
    setSelectedImage: (
        image: ImageDTO | ((prev: ImageDTO | null) => ImageDTO | null)
    ) => void
) => {
    const handleVote = async (imageId: string) => {
        const currentImage = images.find((img) => img.id === imageId);
        if (!currentImage) return;

        const newVoteStatus = !currentImage.isVotedByUser;

        try {
            voteOnImage(imageId, newVoteStatus).catch((error) => {
                captureException("Failed to update vote status.", error);
            });
            const updatedImages = images.map((img) =>
                img.id === imageId
                    ? {
                          ...img,
                          isVotedByUser: newVoteStatus,
                          voteCount: img.voteCount + (newVoteStatus ? 1 : -1),
                      }
                    : img
            );
            setImages(updatedImages);

            // Update selected image state if necessary
            if (selectedImage?.id === imageId) {
                setSelectedImage((prev: ImageDTO | null): ImageDTO | null => {
                    if (!prev) return null;
                    return {
                        ...prev,
                        isVotedByUser: newVoteStatus,
                        voteCount: prev.voteCount + (newVoteStatus ? 1 : -1),
                    };
                });
            }
        } catch (error: any) {
            captureException("Failed to update vote status.", error);
        }
    };

    return { handleVote };
};

export const useEditImage = (
    setIsLightboxOpen: (arg0: boolean) => void,
    handleSetImages: (arg0: ImageDTO[]) => void,
    setLoading: (arg0: boolean) => void,
    setError: (arg0: string) => void
) => {
    const { state, dispatch } = useContext(AppContext);

    const handleEditSubmit = useCallback(
        async (
            editRequest: string,
            isPremium: boolean,
            imageId: string,
            editType: string,
            negativePrompt?: string,
            cfgScale?: number,
            numDetections?: number,
            urlOrFile?: string | File,
            base64Image?: string
        ) => {
            if (!state.isLoggedIn) {
                dispatch({
                    type: AppReducerActionType.SHOW_AUTH_MODAL,
                    payload: {
                        ...state,
                        showAuthModal: true,
                    },
                });
                return;
            }
            setError("");

            if (
                state.userPremiumLevel === UserPremiumType.NONE &&
                state.gold < 1
            ) {
                dispatch({
                    type: AppReducerActionType.SHOW_SUBSCRIPTION_TABLE,
                    payload: {
                        ...state,
                        showSubscriptionTable: true,
                    },
                });
                return;
            } else if (state.gold < 1) {
                dispatch({
                    type: AppReducerActionType.SHOW_PRICING_TABLE,
                    payload: {
                        ...state,
                        showPricingTable: true,
                    },
                });
                return;
            }

            const formData = new FormData();
            formData.append("editRequest", editRequest);
            formData.append("imageId", imageId);
            formData.append("premium", isPremium.toString());
            formData.append("editType", editType);

            if (negativePrompt) {
                formData.append("negativePrompt", negativePrompt);
            }
            if (cfgScale) {
                formData.append("cfgScale", cfgScale.toString());
            }
            if (numDetections) {
                formData.append("numDetections", numDetections.toString());
            }
            if (urlOrFile) {
                if (typeof urlOrFile === "string") {
                    formData.append("faceImageUrl", urlOrFile);
                } else {
                    formData.append("faceImageFile", urlOrFile);
                }
            }

            if (base64Image) {
                formData.append("base64Image", base64Image);
            }

            setLoading(true);
            const { requestId, error } = await editImage(formData);
            setError(typeof error === "string" ? error : error?.message || "");
            const goldCost =
                editType === EditType.FACESWAP
                    ? 4
                    : editType === EditType.UPSCALER
                      ? (cfgScale ?? 1) * 2
                      : 1;
            if (requestId) {
                // Poll for the generated image
                const { image, error } = await poll(
                    async () => {
                        const result = await pollEditImage(requestId);
                        if (result.error && typeof result.error === "object") {
                            console.log("Error details:", result.error);
                        }
                        return result;
                    },
                    ({ image, error }) => {
                        // Continue polling only if there is no image and no critical error
                        if (!!image) return false; // Stop polling when an image is retrieved
                        if (typeof error === "string") return false;
                        if (typeof error === "object") return false;
                        return true; // Continue polling otherwise
                    },
                    5000
                );
                if (error) {
                    if (typeof error === "object") {
                        toast.error(
                            error.message ||
                                "An unknown error occurred while generating the image and your gold has been refunded. Please try again."
                        );
                        dispatch({
                            type: AppReducerActionType.SET_GOLD,
                            payload: { ...state, gold: state.gold + goldCost },
                        });
                    } else if (typeof error === "string") {
                        toast.error(
                            "An unknown error occurred while generating the image and your gold has been refunded. Please try again."
                        );
                        dispatch({
                            type: AppReducerActionType.SET_GOLD,
                            payload: { ...state, gold: state.gold + goldCost },
                        });
                    }
                    setError(error.message || "An unknown error occurred");
                }
                let imagesToSet: ImageDTO[] = [];

                if (Array.isArray(image)) {
                    image.forEach((element: ImageDTO) => {
                        if (element.parentImage) {
                            imagesToSet.push(element.parentImage);
                        }
                        imagesToSet.push(element);
                    });
                } else if (image) {
                    if (image.parentImage) {
                        imagesToSet.push(image.parentImage);
                    }
                    imagesToSet.push(image);
                }

                handleSetImages(imagesToSet);

                if (image && image.length > 0) {
                    dispatch({
                        type: AppReducerActionType.SET_GOLD,
                        payload: {
                            ...state,
                            gold: state.gold - goldCost,
                        },
                    });
                }
            }
            setLoading(false);
            setIsLightboxOpen(false); // Close the lightbox modal
        },
        [
            dispatch,
            handleSetImages,
            setError,
            setIsLightboxOpen,
            setLoading,
            state,
        ]
    );

    return {
        handleEditSubmit,
    };
};

export const useSavePreset = (
    presets: IPresetDTO[],
    setPresets: (presets: IPresetDTO[]) => void,
    setIsSavePresetModalOpen: (isOpen: boolean) => void,
    presetType: PresetType,
    prompt?: { shortName: string },
    formValues?: Record<string, string>,
    charSheetUrl?: string,
    file?: File,
    isPremium?: boolean,
    useCustomConfig?: boolean,
    useModel?: boolean,
    config?: IFreeGenModelDTO,
    simpleForm: boolean = false
) => {
    const { state, dispatch } = useContext(AppContext);
    const handleSave = async (presetName: string) => {
        try {
            if (state.userPremiumLevel === UserPremiumType.NONE) {
                dispatch({
                    type: AppReducerActionType.SHOW_SUBSCRIPTION_TABLE,
                    payload: {
                        ...state,
                        showSubscriptionTable: true,
                    },
                });
                return;
            }
            const newPresetDTO: IPresetDTO = {
                id: uniqueId(),
                name: presetName,
                prompt: prompt?.shortName || "",
                artStyle: formValues?.["Art Style"] || "",
                theme: formValues?.["Theme"] || "",
                requestFields: formValues || {},
                fileUrl: charSheetUrl || "",
                file: file || undefined,
                presetType: presetType,
                additionalField: formValues?.["Description"] || "",
                freeGenModelDTO:
                    (useCustomConfig || useModel) && !isPremium
                        ? config
                        : undefined,
                premium: isPremium ?? false,
                isCustomConfig: useCustomConfig ?? false,
                simpleForm: simpleForm,
            };

            const existingPreset: IPresetDTO | undefined = presets.find(
                (preset) => preset.name === presetName
            );
            let response;

            if (existingPreset) {
                // Update existing preset
                newPresetDTO.id = existingPreset.id;
                response = await updatePreset(existingPreset.id, newPresetDTO);
            } else {
                // Create new preset
                response = await savePreset(newPresetDTO);
            }

            if (response && (response as IPresetDTO).id) {
                const updatedPreset = response as IPresetDTO;

                const updatedPresets = presets.filter(
                    (preset) => preset.id !== updatedPreset.id
                );
                setPresets([...updatedPresets, updatedPreset]);

                toast.success("Preset saved successfully!", {
                    position: "bottom-center",
                });
                setIsSavePresetModalOpen(false);
            } else {
                console.error("Unexpected response:", response);
            }
        } catch (error) {
            console.error("Error saving preset:", error);
        }
    };

    return {
        handleSave,
    };
};
