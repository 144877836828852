import { FC, useCallback, useContext } from "react";
import { Auth } from "@aws-amplify/auth";
import * as Sentry from "@sentry/react";
import { User } from "lucide-react";
import Link from "next/link";
import PopoverWithChildren from "components/atoms/Popover";
import { AppContext } from "lib/contexts";
import { AppReducerActionType, UserPremiumType } from "types";
import { colourScheme } from "utils/styling/colours";
import { Span } from "utils/styling/typography.styled";
import { STRIPE_CUSTOMER_PORTAL } from "utils/urlConstants";
import * as Styled from "./Dropdown.styled";
type DropdownProps = {
  userEmail?: string;
  userPremiumLevel?: UserPremiumType;
};
export const Dropdown: FC<DropdownProps> = ({
  userEmail,
  userPremiumLevel
}) => {
  const {
    state,
    dispatch
  } = useContext(AppContext);
  const handlePricingClick = () => {
    dispatch({
      type: AppReducerActionType.SHOW_PRICING_TABLE,
      payload: {
        ...state,
        showPricingTable: !state.showPricingTable
      }
    });
  };
  const handleSubscribeClick = () => {
    dispatch({
      type: AppReducerActionType.SHOW_SUBSCRIPTION_TABLE,
      payload: {
        ...state,
        showSubscriptionTable: !state.showSubscriptionTable
      }
    });
  };
  const handleMySubscriptionClick = () => {
    window.open(STRIPE_CUSTOMER_PORTAL + "?prefilled_email=" + userEmail, "_blank");
  };
  const handleSignOut = useCallback(async () => {
    setTimeout(async () => {
      await Auth.signOut();
      Sentry.setUser(null);
      window.location.replace(window.location.origin);
    }, 500);
  }, []);
  const isPremium = userPremiumLevel !== UserPremiumType.NONE;
  const subscriptionStyles = {
    NONE: {
      borderColor: colourScheme.greyLight,
      animation: "none"
    },
    BASIC: {
      borderColor: colourScheme.secondaryDark,
      animation: "none"
    },
    PLUS: {
      borderColor: colourScheme.errorDark,
      animation: "subtlePulse"
    },
    PRO: {
      borderColor: colourScheme.alert,
      animation: "subtlePulse"
    },
    ELITE: {
      gradientBorder: `linear-gradient(45deg, ${colourScheme.success}, ${colourScheme.alert}, ${colourScheme.success})`,
      animation: "subtlePulse"
    },
    ULTIMATE: {
      gradientBorder: `linear-gradient(45deg, ${colourScheme.secondaryDark}, ${colourScheme.errorDark}, ${colourScheme.alert})`,
      animation: "subtlePulse 3s infinite, gradientShift 5s linear infinite"
    }
  };
  const subscriptionStyle = subscriptionStyles[state.user?.subscriptionType ?? UserPremiumType.NONE] || subscriptionStyles[UserPremiumType.NONE];
  return <PopoverWithChildren buttonNode={<Styled.ClickableDiv>
                    <Styled.AvatarWrapper subscriptionstyle={subscriptionStyle}>
                        {state.user?.avatarUrl ? <Styled.AvatarImage src={state.user?.avatarUrl} alt={state.username} /> : <Styled.DefaultAvatar>
                                <User size={32} color={colourScheme.fullWhite} />
                            </Styled.DefaultAvatar>}
                    </Styled.AvatarWrapper>
                </Styled.ClickableDiv>} position="bottom" data-sentry-element="PopoverWithChildren" data-sentry-component="Dropdown" data-sentry-source-file="index.tsx">
            {!isPremium && <Styled.PopoverItem onClick={handleSubscribeClick}>
                    <Span>Subscribe</Span>
                </Styled.PopoverItem>}
            {isPremium && <Styled.PopoverItem onClick={handleMySubscriptionClick}>
                    <Span>My Subscription</Span>
                </Styled.PopoverItem>}
            <Styled.PopoverItem onClick={handlePricingClick} data-sentry-element="unknown" data-sentry-source-file="index.tsx">
                <Span data-sentry-element="Span" data-sentry-source-file="index.tsx">Refill Gold</Span>
            </Styled.PopoverItem>
            <Link href={"/profile"} data-sentry-element="Link" data-sentry-source-file="index.tsx">
                <Styled.PopoverItem data-sentry-element="unknown" data-sentry-source-file="index.tsx">
                    <Span data-sentry-element="Span" data-sentry-source-file="index.tsx">Profile</Span>
                    <Styled.NewBadge data-sentry-element="unknown" data-sentry-source-file="index.tsx">✨ NEW ✨</Styled.NewBadge>
                </Styled.PopoverItem>
            </Link>
            <Styled.PopoverItem onClick={handleSignOut} data-sentry-element="unknown" data-sentry-source-file="index.tsx">
                <Span data-sentry-element="Span" data-sentry-source-file="index.tsx">Sign Out</Span>
            </Styled.PopoverItem>
        </PopoverWithChildren>;
};