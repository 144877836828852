import React, { createContext, useContext, useEffect, useState } from "react";
import { fetchCompletedTutorials, markTutorialAsCompleted } from "lib/actions";
import { TutorialState } from "types";
interface TutorialContextProps {
  tutorialState: TutorialState | null;
  tutorialStateInitialized: boolean;
  startTutorial: (name: keyof TutorialState) => void;
  completeTutorial: (name: keyof TutorialState) => void;
}
export const defaultState: TutorialState = {
  generateImageForm: {
    started: false,
    completed: false
  },
  imageViewer: {
    started: false,
    completed: false
  },
  controlsContainer: {
    started: false,
    completed: false
  },
  shareModal: {
    started: false,
    completed: false
  },
  lightbox: {
    started: false,
    completed: false
  },
  promptingTutorial: {
    started: false,
    completed: false
  }
};
export const TutorialContext = createContext<TutorialContextProps | undefined>(undefined);
export const TutorialProvider: React.FC<{
  children: React.ReactNode;
}> = ({
  children
}) => {
  const [tutorialState, setTutorialState] = useState<TutorialState | null>(null);
  const [tutorialStateInitialized, setTutorialStateInitialized] = useState(false);
  useEffect(() => {
    const initializeTutorialState = async () => {
      let storedState = defaultState;
      if (typeof window !== "undefined") {
        const storedStateJSON = localStorage.getItem("tutorialState");
        if (storedStateJSON) {
          storedState = JSON.parse(storedStateJSON);
        }
      }

      // Fetch completed tutorials from the API
      const response = await fetchCompletedTutorials(); // Your API call here
      if (response.tutorials) {
        const completedTutorials = response.tutorials.reduce((acc, tutorial) => {
          acc[tutorial.tutorialId as keyof TutorialState] = {
            started: true,
            completed: tutorial.completed
          };
          return acc;
        }, {} as TutorialState);
        storedState = {
          ...storedState,
          ...completedTutorials
        };
      } else {
        console.error("Failed to fetch completed tutorials:", response.error);
      }
      setTutorialState(storedState);
      setTutorialStateInitialized(true);
    };
    initializeTutorialState();
  }, []);
  useEffect(() => {
    if (tutorialState && typeof window !== "undefined") {
      localStorage.setItem("tutorialState", JSON.stringify(tutorialState));
    }
  }, [tutorialState]);
  const startTutorial = (name: keyof TutorialState) => {
    7;
    if (!tutorialState) return;
    setTutorialState(prev => ({
      ...prev!,
      [name]: {
        ...prev![name],
        started: true
      }
    }));
  };
  const completeTutorial = async (name: keyof TutorialState) => {
    if (!tutorialState) return;
    setTutorialState(prev => ({
      ...prev!,
      [name]: {
        started: false,
        completed: true
      }
    }));

    // Mark the tutorial as completed in the backend
    const response = await markTutorialAsCompleted(name);
    if (response.error) {
      console.error("Failed to mark tutorial as completed:", response.error);
    }
  };
  return <TutorialContext.Provider value={{
    tutorialState,
    tutorialStateInitialized,
    startTutorial,
    completeTutorial
  }} data-sentry-element="unknown" data-sentry-component="TutorialProvider" data-sentry-source-file="index.tsx">
            {children}
        </TutorialContext.Provider>;
};
export const useTutorial = () => {
  const context = useContext(TutorialContext);
  if (!context) {
    throw new Error("useTutorial must be used within a TutorialProvider");
  }
  return context;
};