import { styled } from "styled-components";

import { Button as StyledButton } from "components/atoms/Button";
import { device } from "utils/sizes";
import { colourScheme } from "utils/styling/colours";
import { Small } from "utils/styling/typography.styled";

export const Container = styled.div`
    display: flex;
    gap: 0.5rem;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    .span {
        white-space: normal; // Allow text to wrap
        word-wrap: break-word; // Break words if necessary
    }
`;

export const Button = styled(StyledButton)`
    background-color: ${colourScheme.primaryDark};
    color: ${colourScheme.white};
    width: 100%;

    &:disabled {
        cursor: default;
        opacity: 0.8;
    }

    &:hover:enabled,
    &:focus:enabled {
        background-color: ${colourScheme.primaryDark};
        border-radius: 0.25rem;
        cursor: pointer;
    }
    padding: 0.3rem 0.4rem !important; // Adjusted padding for small screens
`;

export const NewLabel = styled(Small)`
    vertical-align: top;
    font-size: 0.5rem !important;
    color: ${colourScheme.alert};

    ${device.xl} {
        font-size: 0.75rem !important;
    }
`;
