import { styled } from "styled-components";

import { device } from "utils/sizes";
import { colourScheme } from "utils/styling/colours";

export const SidebarToggle = styled.div`
    display: flex;
    ${device.lg} {
        display: none;
    }
    height: 3.375rem;
    width: 4rem;
    svg,
    img {
        height: 100%;
        width: 100%;
    }
    cursor: pointer;
`;

export const SidebarContainer = styled.div<{
    $active: boolean;
}>`
    display: flex;
    ${device.lg} {
        display: none;
    }
    width: ${(props) => (props.$active ? "20rem" : "0px")};
    opacity: ${(props) => (props.$active ? 1 : 0)};
    padding: 2rem 1rem;
    white-space: nowrap;
    overflow: hidden;
    overflow-y: auto;
    position: absolute;
    top: 70px;
    right: 0;
    transition: all 0.2s;
    height: calc(100vh - 70px);
    z-index: 1000;
    background-color: ${colourScheme.primaryDark};
    flex-direction: column;
    & > div {
        margin-bottom: 2rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        align-items: center;
        width: 100%;
    }
`;
