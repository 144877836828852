import { FC, useContext } from "react";
import { Sparkles } from "lucide-react";
import Image from "next/image";
import Link from "next/link";
import { Button } from "@/components/ui/button";
import { Dropdown } from "components/atoms/Dropdown";
import { GemCounter } from "components/atoms/GemCounter";
import Navigation from "components/atoms/Navigation";
import { AuthButton, MyGalleryButton, ProfileButton, SignOutButton } from "components/molecules/AuthButton";
import { GemCounterHeader, SubscribeButton } from "components/molecules/GemCounterHeader";
import Notifications from "components/molecules/Notifications";
import { Sidebar } from "components/molecules/Sidebar";
import { AppContext } from "lib/contexts";
import { useGclid, useReferralCode } from "lib/hooks";
import { UserPremiumType } from "types";
import { Title } from "utils/styling/typography.styled";
import * as Styled from "./Header.styled";
import Icon from "./icon.png";
type HeaderProps = {
  isLoggedIn: boolean;
  fetched?: boolean;
  userPremiumLevel?: UserPremiumType;
  userEmail?: string;
  username?: string;
  gold?: number;
  isSidebar?: boolean;
};
const HeaderButtons: FC<HeaderProps> = ({
  isLoggedIn,
  userPremiumLevel,
  userEmail,
  gold,
  isSidebar
}) => {
  return isLoggedIn ? <Styled.HeaderButtonsContainer>
            <Navigation />
            {!isSidebar && <Styled.VerticalRule />}
            {!isSidebar && <Link href="/generate" title="Generate Image">
                    <Button className="bg-gradient-to-r from-emerald-500 to-emerald-600 hover:from-emerald-600 hover:to-emerald-700 text-white font-medium py-2 px-4 rounded-md shadow-md hover:shadow-lg transition-all duration-200 ease-in-out flex items-center space-x-2">
                        <Sparkles className="w-5 h-5" />
                        <span>Generate</span>
                    </Button>
                </Link>}
            {gold && <GemCounter gold={gold} />}
            {!isSidebar && <>
                    <Notifications />
                    <Dropdown userPremiumLevel={userPremiumLevel} userEmail={userEmail} />
                </>}
        </Styled.HeaderButtonsContainer> : <Styled.HeaderButtonsContainer>
            <Navigation />
            {!isSidebar && <Styled.VerticalRule />}
            {!isSidebar && <Link href="/generate" title="Generate Image">
                    <Button className="bg-gradient-to-r from-emerald-500 to-emerald-600 hover:from-emerald-600 hover:to-emerald-700 text-white font-medium py-2 px-4 rounded-md shadow-md hover:shadow-lg transition-all duration-200 ease-in-out flex items-center space-x-2">
                        <Sparkles className="w-5 h-5" />
                        <span>Generate</span>
                    </Button>
                </Link>}
            {!isSidebar && <AuthButton />}
        </Styled.HeaderButtonsContainer>;
};
export const Header: FC<HeaderProps> = ({
  isLoggedIn,
  userPremiumLevel,
  userEmail,
  username
}) => {
  const {
    state
  } = useContext(AppContext);
  useReferralCode();
  useGclid();
  return <Styled.Header data-sentry-element="unknown" data-sentry-component="Header" data-sentry-source-file="index.tsx">
            <Link title="Home" href="/" data-sentry-element="Link" data-sentry-source-file="index.tsx">
                <Styled.LogoContainer data-sentry-element="unknown" data-sentry-source-file="index.tsx">
                    <Image height={40} width={40} src={Icon} alt="CharGen Icon" data-sentry-element="Image" data-sentry-source-file="index.tsx" />
                    <Title data-sentry-element="Title" data-sentry-source-file="index.tsx">
                        CharGen
                        {userPremiumLevel !== UserPremiumType.NONE && <Styled.PremiumLevelIndicator>
                                {userPremiumLevel}
                            </Styled.PremiumLevelIndicator>}
                    </Title>
                </Styled.LogoContainer>
            </Link>
            <Styled.AuthContainer data-sentry-element="unknown" data-sentry-source-file="index.tsx">
                <HeaderButtons isLoggedIn={isLoggedIn} userPremiumLevel={userPremiumLevel} userEmail={userEmail} gold={state.gold} isSidebar={false} data-sentry-element="HeaderButtons" data-sentry-source-file="index.tsx" />
            </Styled.AuthContainer>
            {isLoggedIn && <Styled.NotificationContainer>
                    <Notifications />
                </Styled.NotificationContainer>}
            <Sidebar data-sentry-element="Sidebar" data-sentry-source-file="index.tsx">
                <GemCounterHeader data-sentry-element="GemCounterHeader" data-sentry-source-file="index.tsx" />
                <Styled.HorizontalRule data-sentry-element="unknown" data-sentry-source-file="index.tsx" />
                <HeaderButtons isLoggedIn={isLoggedIn} userPremiumLevel={userPremiumLevel} userEmail={userEmail} username={username} isSidebar={true} data-sentry-element="HeaderButtons" data-sentry-source-file="index.tsx" />
                <Styled.HorizontalRule data-sentry-element="unknown" data-sentry-source-file="index.tsx" />
                <SubscribeButton userPremiumLevel={userPremiumLevel} data-sentry-element="SubscribeButton" data-sentry-source-file="index.tsx" />
                <div className="w-full">
                    <Link href="/generate" title="Generate Image" data-sentry-element="Link" data-sentry-source-file="index.tsx">
                        <Button className="w-full bg-gradient-to-r from-emerald-500 to-emerald-600 hover:from-emerald-600 hover:to-emerald-700 text-white font-medium py-2 px-4 rounded-md shadow-md hover:shadow-lg transition-all duration-200 ease-in-out flex items-center space-x-2" data-sentry-element="Button" data-sentry-source-file="index.tsx">
                            <Sparkles className="w-5 h-5" data-sentry-element="Sparkles" data-sentry-source-file="index.tsx" />
                            <span>Generate</span>
                        </Button>
                    </Link>
                </div>
                {!isLoggedIn && <div className="w-full">
                        <AuthButton />
                    </div>}
                {isLoggedIn && <Styled.ShowMenu>
                        <ProfileButton />
                        <MyGalleryButton />
                        <SignOutButton />
                    </Styled.ShowMenu>}
            </Sidebar>
        </Styled.Header>;
};