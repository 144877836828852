import { FC, useContext } from "react";
import { ButtonType } from "components/atoms/Button/types";
import { GemCounter } from "components/atoms/GemCounter";
import { AppContext } from "lib/contexts";
import { AppReducerActionType, UserPremiumType } from "types";
import { Span } from "utils/styling/typography.styled";
import * as Styled from "./GemCounterHeader.styled";
type HeaderProps = {
  userPremiumLevel?: UserPremiumType;
  userEmail?: string;
};
export const SubscribeButton: FC<HeaderProps> = ({
  userPremiumLevel
}) => {
  const {
    state,
    dispatch
  } = useContext(AppContext);
  const handleSubscribeClick = () => {
    dispatch({
      type: AppReducerActionType.SHOW_SUBSCRIPTION_TABLE,
      payload: {
        ...state,
        showSubscriptionTable: !state.showSubscriptionTable
      }
    });
  };
  const isPremium = userPremiumLevel !== UserPremiumType.NONE;
  return !isPremium ? <Styled.Container>
            <Styled.Button variant={ButtonType.SUCCESS} onClick={handleSubscribeClick}>
                <Span>Subscribe</Span>
            </Styled.Button>
        </Styled.Container> : null;
};
export const GemCounterHeader: FC<HeaderProps> = () => {
  const {
    state,
    dispatch
  } = useContext(AppContext);
  const handlePricingClick = () => {
    dispatch({
      type: AppReducerActionType.SHOW_PRICING_TABLE,
      payload: {
        ...state,
        showPricingTable: !state.showPricingTable
      }
    });
  };
  return <Styled.Container data-sentry-element="unknown" data-sentry-component="GemCounterHeader" data-sentry-source-file="index.tsx">
            <GemCounter gold={state.gold} data-sentry-element="GemCounter" data-sentry-source-file="index.tsx" />
            <Styled.Button onClick={handlePricingClick} data-sentry-element="unknown" data-sentry-source-file="index.tsx">
                <Span data-sentry-element="Span" data-sentry-source-file="index.tsx">Get More Gold</Span>
            </Styled.Button>
        </Styled.Container>;
};