// src/lib/utils.ts

import clsx from "clsx";

import { PresetType } from "@/types";

/**
 * Concatenate class names conditionally.
 *
 * @param classes - An array of class names or conditional expressions.
 * @returns A string of concatenated class names.
 */
export function cn(...classes: (string | undefined | null | false)[]) {
    return clsx(classes);
}

// Function to map PresetTypes to strings
export function mapPresetTypeToString(presetType: PresetType): string {
    switch (presetType) {
        case PresetType.CHARACTER:
            return "characters";
        case PresetType.ITEM:
            return "items";
        case PresetType.NPC:
            return "npcs";
        case PresetType.MONSTER:
            return "monsters";
        case PresetType.LANDSCAPE:
            return "landscapes";
        case PresetType.BATTLEMAP:
            return "battlemaps";
        default:
            return "characters";
    }
}

export function mapCategoryToPresetType(category: string): PresetType {
    switch (category) {
        case "characters":
            return PresetType.CHARACTER;
        case "items":
            return PresetType.ITEM;
        case "npcs":
            return PresetType.NPC;
        case "monsters":
            return PresetType.MONSTER;
        case "landscapes":
            return PresetType.LANDSCAPE;
        case "battlemaps":
            return PresetType.BATTLEMAP;
        default:
            return PresetType.CHARACTER;
    }
}
