"use client";

import { useEffect, useReducer, useRef, useState } from "react";
import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import * as Sentry from "@sentry/react";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
declare global {
  interface Window {
    Tawk_API?: {
      onLoad?: () => void;
      setAttributes: (attributes: {
        [key: string]: any;
      }, callback: (error?: any) => void) => void;
      $socket: any;
    };
  }
}
import dynamic from "next/dynamic";
import ChangelogBanner from "@/components/molecules/ChangelogBanner";
import { Header } from "@/components/molecules/Header";
import { StyledToastContainer } from "components/atoms/Toast/Toast.styled";
import { CommonModals } from "components/molecules/CommonModals";
import CookieConsent from "components/molecules/CookieConsent";
// import { RegisterBanner } from "components/molecules/RegisterBanner";
import { TutorialProvider } from "components/organisms/TutorialProvider";
import { getCurrentUserInfo } from "lib/actions";
import { AppContext } from "lib/contexts";
import { appReducer } from "lib/reducers";
import { AppReducerActionType, UserPremiumType } from "types";
import "react-toastify/dist/ReactToastify.css";
const queryClient = new QueryClient();
const LayoutWrapper = React.memo(function LayoutWrapper({
  children
}: Readonly<{
  children: React.ReactNode;
}>) {
  const [fetched, setFetched] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [state, dispatch] = useReducer(appReducer, {
    showPricingTable: false,
    showSubscriptionTable: false,
    gold: 0,
    cookieConsentGiven: true,
    isLoggedIn: false,
    showAuthModal: false,
    userPremiumLevel: UserPremiumType.NONE,
    userFreeGens: 10,
    isOverlayVisible: false,
    username: "",
    email: "",
    referralCode: "",
    gclid: "",
    dailyLoginBonus: null,
    user: null
  });
  const [username, setUsername] = useState<string>("");
  const [unseenChangelogs, setUnseenChangelogs] = useState<boolean>(false);
  const tawkMessengerRef = useRef(null);
  const Footer = dynamic(() => import("components/molecules/Footer"), {
    ssr: false
  });
  const onLoad = () => {
    // Use window.Tawk_API to set attributes
    if (window.Tawk_API && window.Tawk_API.setAttributes && email) {
      if (window.Tawk_API.$socket && window.Tawk_API.$socket.connected) {
        window.Tawk_API.setAttributes({
          name: username,
          email: email
        }, function (error: any) {
          if (error) {
            console.error("Error setting Tawk.to user attributes", error);
          }
        });
      }
    } else {
      console.error("Tawk_API or setAttributes is not available");
    }
  };

  // Fetch user info
  useEffect(() => {
    (async () => {
      try {
        const {
          user
        } = await getCurrentUserInfo();
        if (user) {
          try {
            dispatch({
              type: AppReducerActionType.SET_GOLD,
              payload: {
                ...state,
                gold: user?.gold || 0
              }
            });
            setEmail(user.email || "");
            Sentry.setUser({
              email: user.email
            });
            setUsername(user.username);
            setUnseenChangelogs(user.hasUnseenChangelogs || false);
            dispatch({
              type: AppReducerActionType.SET_LOGGED_IN,
              payload: {
                ...state,
                isLoggedIn: true
              }
            });
            dispatch({
              type: AppReducerActionType.SET_USER_PREMIUM_LEVEL,
              payload: {
                ...state,
                userPremiumLevel: user.subscriptionType ?? UserPremiumType.NONE
              }
            });
            dispatch({
              type: AppReducerActionType.SET_USER_FREE_GENS,
              payload: {
                ...state,
                userFreeGens: 10 - (user.requestsInLastDay ?? 0)
              }
            });
            dispatch({
              type: AppReducerActionType.SET_USERNAME,
              payload: {
                ...state,
                username: user.username
              }
            });
            if (user.referralCode) {
              dispatch({
                type: AppReducerActionType.SET_REFERRAL_CODE,
                payload: {
                  ...state,
                  referralCode: user.referralCode
                }
              });
            }
            if (user.dailyGoldBonus) {
              dispatch({
                type: AppReducerActionType.SET_DAILY_LOGIN_BONUS,
                payload: {
                  ...state,
                  dailyLoginBonus: user.dailyGoldBonus
                }
              });
            }
            dispatch({
              type: AppReducerActionType.SET_USER,
              payload: {
                ...state,
                user: user
              }
            });
          } catch (error) {
            dispatch({
              type: AppReducerActionType.SET_LOGGED_IN,
              payload: {
                ...state,
                isLoggedIn: false
              }
            });
          } finally {
            setFetched(true);
          }
        }
      } catch (e: any) {
        console.log(e);
      }
      setFetched(true);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (fetched) {
      onLoad(); // Only load script when user info fetching is done
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetched]);
  useEffect(() => {
    document.body.style.overflow = state.isOverlayVisible ? "hidden" : "unset";
  }, [state.isOverlayVisible]);
  return <QueryClientProvider client={queryClient}>
            <AppContext.Provider value={{
      state,
      dispatch
    }}>
                <TutorialProvider>
                    <Header isLoggedIn={state.isLoggedIn} fetched={fetched} userPremiumLevel={state.userPremiumLevel} userEmail={email} username={username} />
                    <CookieConsent />
                    {/* {!state.isLoggedIn && fetched && <RegisterBanner />} */}
                    {state.isLoggedIn && fetched && unseenChangelogs && <>
                            <ChangelogBanner />
                        </>}
                    {children}
                    <Footer />
                    <CommonModals email={email} />
                    <TawkMessengerReact propertyId="670976d12480f5b4f58c3c11" widgetId="1i9ufg94e" ref={tawkMessengerRef} />
                </TutorialProvider>
            </AppContext.Provider>
            <StyledToastContainer />
        </QueryClientProvider>;
});
export default LayoutWrapper;