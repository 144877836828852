// components/molecules/GoldStreakBanner.styled.ts

import styled from "styled-components";

import { device } from "utils/sizes";
import { colourScheme } from "utils/styling/colours";

export const BannerContainer = styled.div`
    position: relative; /* Changed from fixed */
    max-width: 100%;
    width: 100%;
    border-radius: 0.5rem;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    pointer-events: auto;
    padding: 0.75rem;
    transition:
        opacity 0.3s ease-in-out,
        transform 0.3s ease-in-out;

    ${device.md} {
        padding: 0rem;
    }
`;
export const BannerContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    position: relative;

    background-color: ${colourScheme.primary};
    color: ${colourScheme.success};
    border-radius: 0.5rem;
    border: 1px solid ${colourScheme.primaryLight};
    padding: 1rem;
    width: 100%;
`;

export const StreakInfo = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
`;

export const StreakText = styled.div`
    font-size: 1rem;
    font-weight: bold;
    display: flex;
    align-items: center;
    gap: 0.5rem;
`;

export const BonusText = styled.div`
    font-size: 0.75rem;
    color: ${colourScheme.greyLight};
`;

export const RewardsInfo = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
`;

export const RewardText = styled.div`
    display: flex;
    align-items: center;
    font-size: 0.8rem;
    gap: 0.25rem;
`;

export const MotivationalText = styled.div`
    font-size: 0.8rem;
    color: ${colourScheme.greyLight};
`;

export const CapMessage = styled.div`
    color: ${colourScheme.white};
    font-size: 0.75rem;
`;

export const CloseButton = styled.button`
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    background: transparent;
    border: none;
    cursor: pointer;
    color: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;

    &:hover {
        opacity: 0.7;
    }
`;
