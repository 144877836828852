import { FC } from "react";
import * as DialogPrimitive from "@radix-ui/react-dialog";
import * as Styled from "./Modal.styled";
type ModalProps = {
  children: React.ReactNode;
  open: boolean;
  handleBackdropClick: () => void;
  disableBackdropClick?: boolean;
  controls?: boolean;
  moveBack?: () => void;
  moveForward?: () => void;
};
export const Modal: FC<ModalProps> = ({
  children,
  open,
  handleBackdropClick,
  disableBackdropClick = false
}) => {
  return <DialogPrimitive.Root open={open} onOpenChange={isOpen => {
    if (!isOpen) {
      handleBackdropClick();
    }
  }} data-sentry-element="unknown" data-sentry-component="Modal" data-sentry-source-file="index.tsx">
            <DialogPrimitive.Portal data-sentry-element="unknown" data-sentry-source-file="index.tsx">
                {/* Overlay */}
                <Styled.ModalOverlay data-sentry-element="unknown" data-sentry-source-file="index.tsx" /> {/* Content */}
                <Styled.ModalContent className="bg-slate-950" aria-describedby="modal-description" onPointerDownOutside={event => {
        if (disableBackdropClick) {
          event.preventDefault();
        }
      }} data-sentry-element="unknown" data-sentry-source-file="index.tsx">
                    {/* Modal Content */}
                    <Styled.ModalContainer data-sentry-element="unknown" data-sentry-source-file="index.tsx">{children}</Styled.ModalContainer>
                </Styled.ModalContent>
            </DialogPrimitive.Portal>
        </DialogPrimitive.Root>;
};