import { LinearProgress, linearProgressClasses } from "@mui/material";
import styled from "styled-components";

import { colourScheme } from "utils/styling/colours";

export const ProgressBarContainer = styled.div`
    width: 100%;
    margin-top: 0.5rem;
`;

export const StyledLinearProgress = styled(LinearProgress)`
    height: 0.5rem;
    border-radius: 0.5rem;

    &.${linearProgressClasses.colorPrimary} {
        background-color: ${colourScheme.secondary};
    }

    & .${linearProgressClasses.bar} {
        background-color: #ffd700; /* Gold color */
    }
`;
