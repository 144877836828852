import React, { forwardRef, ReactNode } from "react";
import * as Styled from "./Popover.styled";
import { PopoverPanelVariant } from "./types";
interface PopoverWithChildrenProps {
  children: ReactNode;
  buttonNode: ReactNode;
  position?: "top" | "bottom" | "left" | "right" | "bottomLeft";
  panelVariant?: PopoverPanelVariant;
}
const PopoverWithChildren = forwardRef<HTMLButtonElement, PopoverWithChildrenProps>(({
  children,
  buttonNode,
  position = "top",
  panelVariant = PopoverPanelVariant.BASE
}, ref) => {
  const PanelComponent = panelVariant === PopoverPanelVariant.NOTIFICATIONS ? Styled.NotificationsPopoverPanel : Styled.BasePopoverPanel;
  return <Styled.Popover>
                <Styled.PopoverButton ref={ref}>
                    {buttonNode}
                </Styled.PopoverButton>
                <PanelComponent position={position}>
                    <Styled.PopoverContent>{children}</Styled.PopoverContent>
                </PanelComponent>
            </Styled.Popover>;
});
PopoverWithChildren.displayName = "PopoverWithChildren";
export default PopoverWithChildren;