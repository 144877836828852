import React from "react";
import * as Styled from "./ProgressBar.styled";
interface ProgressBarProps {
  current: number;
  total: number;
}
export const ProgressBar: React.FC<ProgressBarProps> = ({
  current,
  total
}) => {
  const percentage = current / total * 100;
  return <Styled.ProgressBarContainer data-sentry-element="unknown" data-sentry-component="ProgressBar" data-sentry-source-file="index.tsx">
            <Styled.StyledLinearProgress variant="determinate" value={percentage} data-sentry-element="unknown" data-sentry-source-file="index.tsx" />
        </Styled.ProgressBarContainer>;
};