import { Popover as HeadlessPopover } from "@headlessui/react";
import styled, { css } from "styled-components";

import { device } from "utils/sizes";
import { colourScheme, hexToRgba } from "utils/styling/colours";

const positionStyles: { [key: string]: any } = {
    top: css`
        top: 0;
        transform: translate(0%, -100%);
    `,
    bottom: css`
        top: 100%;
        left: 50%;
        transform: translate(-50%, 0.5rem);
    `,
    left: css`
        right: 100%;
        top: 50%;
        transform: translate(-0.5rem, -50%);
    `,
    right: css`
        left: 100%;
        top: 50%;
        transform: translate(0.5rem, -50%);
    `,
    bottomLeft: css`
        top: 100%;
        right: 0;
    `,
};

const basePopoverPanelStyles = css`
    position: absolute;
    z-index: 10;
    background-color: ${hexToRgba(colourScheme.primaryDark, 1)};
    border: 1px solid ${colourScheme.primary};
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 1rem;
    border-radius: 0.5rem;
    max-width: 100%;
    width: calc(100vw-2rem);
    overflow-y: auto;
    max-height: 80vh;
    min-width: 10rem;
`;

export const Popover = styled(HeadlessPopover)`
    position: relative;
`;

export const PopoverButton = styled(HeadlessPopover.Button)`
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    cursor: pointer;
    &:focus {
        outline: none;
    }
`;

export const BasePopoverPanel = styled(HeadlessPopover.Panel)<{
    position: string;
}>`
    ${basePopoverPanelStyles}
    ${({ position }) => positionStyles[position]}
`;

export const NotificationsPopoverPanel = styled(BasePopoverPanel)`
    ${basePopoverPanelStyles}
    min-width: 17rem;

    ${device.md} {
        min-width: 20rem;
    }
    ${device.lg} {
        min-width: 25rem;
    }
    ${({ position }) => positionStyles[position]}
`;

export const PopoverContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    font-size: 0.7rem;
    border-radius: 0.5rem;
`;
